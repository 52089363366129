import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KeycloakGuard } from '@badgertechnologies/liblogin.js';
import { roleGuardFn } from '@ng-cloud/badger-core/common/guards/functional-guards';

const routes: Routes = [
  {
    path: 'reviews', loadChildren: () => import('./review/review.module').then(m => m.ReviewModule),
    canMatch: [roleGuardFn], data: { roles: ['admin', 'insight_moderator'] },
    canActivate: [KeycloakGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [KeycloakGuard]
  },
  { path: '', redirectTo: 'reviews', pathMatch: 'full' },
  { path: 'restricted', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})]
})
export class AppRoutingModule {
}

