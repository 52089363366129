import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ISelectOption } from "../../../models/interfaces/analytics";
import { MultiSelectComponent } from "../multi-select.component";
import { MatSelectionList } from "@angular/material/list";


@Component({
    selector: 'bt-multi-select-popup',
    templateUrl: 'multi-select-popup.component.html',
    styleUrls: ['./multi-select-popup.component.scss'],
})
export class MultiSelectPopupDialogComponent implements AfterViewInit {
    @ViewChild(MatSelectionList) selectList: MatSelectionList;
    @ViewChild('searchInput') searchElement: ElementRef;
    @ViewChild('container') container: ElementRef;

    readonly parent: MultiSelectComponent;

    private searchFocused = false;

    constructor(
        public dialogRef: MatDialogRef<MultiSelectPopupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            parent: MultiSelectComponent,
            multiple: boolean,
            title: string,
            selectedItems: ISelectOption[]
        },
    ) {
        this.parent = data.parent;
    }

    ngAfterViewInit(): void {

        if (this.parent.searchAvailable) {
            setTimeout(() => this.searchElement.nativeElement.focus(), 200);
            this.searchFocused = true;
        }
    }

    onSelectionChange(e) {
        this.parent.onSelectionChange(e.options[0].value, e.options[0].selected);

        if (!this.parent.multiple) { // auto close for single select mode
            this.dialogRef.close();
        }
    }

    adjustFormat(str: number | string): number | string {
        // make spaces meaningful, note &nbsp; doesn't work from .ts methods.
        if (typeof str == 'string') {
            return str.replace(/ /g, '\xA0');
        }

        return str;
    }

    onListScroll(e) {
        this.parent.onListScroll(e, this.container.nativeElement.offsetHeight);
    }

    // option event is called first - override Enter and ESC
    onListOptionKeyDown(e: KeyboardEvent) {

        if (e.key == 'Enter') {
            // Apply changes and close
            e.stopPropagation();
            this.dialogRef.close();
        }
        if (e.code == 'Escape') {
            //cancel and close
            this.parent.selectedItemsCache = [...this.parent.selectedItems];
        }

        const listCtrlKeyCodes = ['ArrowDown', 'ArrowUp', 'Space', 'Enter', 'PageDown', 'PageUp', 'Home', 'End', 'Escape', 'Tab'];
        if (!listCtrlKeyCodes.includes(e.code)) {
          if (this.parent.searchAvailable) { // focus on search field

            this.searchElement.nativeElement.focus();
            //setTimeout(() => this.searchElement.nativeElement.focus(), 100);

            this.searchFocused = true;
            e.stopPropagation();
            e.stopImmediatePropagation();
          }
        }

    }


    onListKeyDown(e: KeyboardEvent) {

        if (this.searchFocused) {
            // if search input is focused - disable list manipulation
            if (e.key != 'Tab') {
              e.stopPropagation();
            }
            return;
        }

    }

    onSearchFocus() {
        this.searchFocused = true;
    }

    onSearchBlur() {
        this.searchFocused = false;
    }

    onSearchKeyUp(e: KeyboardEvent) {
        e.stopPropagation();
        this.parent.search.emit(this.parent.searchEventValue(e));
    }

    onSearchKeyDown(e: KeyboardEvent) {

        const listCtrlKeyCodes = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp', 'Tab'];
        if (!listCtrlKeyCodes.includes(e.code)) {
          e.stopPropagation();
          e.stopImmediatePropagation();
        }

        if (e.code == 'Escape') {
            //cancel and close
            this.parent.selectedItemsCache = [...this.parent.selectedItems];
            this.dialogRef.close();
        }

        // Process Select All event:
        if (e.code == 'KeyA' && e.ctrlKey) {
          this.searchElement.nativeElement.select();
          e.stopPropagation();
        }
      }

}
