import { Component } from '@angular/core';

@Component({
    selector: 'bt-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss'],
    standalone: false
})
export class SpinnerOverlayComponent {
  constructor() {
  }
}
