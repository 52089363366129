import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { User } from './user';


export class InspectAudit extends AbstractApiModel<InspectAudit> {
  completedCount: number;
  eligibleCount: number;
  grade: number;
  isComplete: boolean;
  periodStart: Date;
  periodEnd: Date;
  targetPercentage: number;
  targetReviewCount: number;

  auditorId: number;
  moderatorId: number;
  auditor: User;
  moderator: User;

  deserialize(json: any): this {
    this.completedCount = json.completed_count;
    this.eligibleCount = json.eligible_count;
    this.grade = +json.grade;
    this.isComplete = json.is_complete;
    this.periodStart = json.period_start;
    this.periodEnd = json.period_end;
    this.targetPercentage = +json.target_percentage;
    this.moderatorId = json.moderator_id;
    this.auditorId = json.auditor_id;
    this.targetReviewCount = json.target_review_count;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      completed_count: this.completedCount,
      eligible_count: this.eligibleCount,
      grade: this.grade,
      is_complete: this.isComplete,
      period_start: this.periodStart,
      period_end: this.periodEnd,
      target_percentage: this.targetPercentage,
      moderator_id: this.moderatorId,
      auditor_id: this.auditorId
    });
  }

  getPercentComplete(): number {
    return 100 * this.completedCount / this.targetReviewCount;
  }
}
