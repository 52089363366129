import { AbstractApiModel } from './abstract-api-model';

export class TaskResolution extends AbstractApiModel<TaskResolution> {
  organizationId: number;
  name: string;
  description: string;
  resolutionType: string;

  deserialize(json: any): this {
    this.organizationId = json.organization_id;
    this.name = json.name;
    this.description = json.description;
    this.resolutionType = json.resolution_type;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {});
  }
}
