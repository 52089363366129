import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convert',
    standalone: false
})
export class ConversionPipe implements PipeTransform {
  transform(value: number, units: string): number {
    switch (units) {
      case 'in-m': {
        return value / 39.37;
      }

      case 'in-in': {
        return value;
      }

      default: {
        return value;
      }
    }
  }
}
