<mat-card appearance="outlined" *ngIf="assessmentReview" class="capture-event-assessment-review-detail-map-popup">
  <mat-card-content fxLayout="row">
    <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="selectReview(+$event.tab.textLabel)" [(selectedIndex)]="activeTab"
                   fxFlex="100%" animationDuration="0ms">
      <div *ngFor="let view of captureEvent.views">
        <mat-tab *ngFor="let tabReview of view.assessmentReviews" label="{{tabReview.id}}">
          <ng-template mat-tab-label>
            {{view.viewType | startCase}}
          </ng-template>
          <div fxLayout="row" fxLayoutGap="10px">
            <div class="review-popup-info" fxLayout="column">
              <ng-container
                *ngTemplateOutlet="assessmentReviewDetailTemplate; context: { $implicit: tabReview }"></ng-container>
              <mat-list dense class="dense-detail-list" *ngIf="inspectAssessment">
                <mat-list-item>
                  <span matListItemTitle>{{inspectAssessment.description}}</span>
                  <span matListItemLine>Assessment </span>
                </mat-list-item>
                <mat-list-item *ngIf="assessmentReview.assessmentResult">
                  <span matListItemTitle>{{assessmentReview.assessmentResult}}</span>
                  <span matListItemLine>Result </span>
                </mat-list-item>
              </mat-list>
            </div>
            <mat-tab-group mat-stretch-tabs="false" class="imageTabGroup" animationDuration="0ms">
              <mat-tab *ngFor="let image of assessmentReview.images" label="{{image.description }}">
                <img src="{{image.url}}" alt="assessment review image"/>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-tab>
      </div>
    </mat-tab-group>
    <div fxLayoutAlign="end start">
      <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
