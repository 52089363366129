import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bt-map-save-controls',
    templateUrl: './map-save-controls.component.html',
    styleUrls: ['./map-save-controls.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MapSaveControlsComponent {
  @Output('save') saveEmitter: EventEmitter<any> = new EventEmitter();
  @Output('cancel') cancelEmitter: EventEmitter<any> = new EventEmitter();

  save() {
    this.saveEmitter.emit();
  }

  cancel() {
    this.cancelEmitter.emit();
  }
}
