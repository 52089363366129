import { AbstractControl, ValidationErrors } from '@angular/forms';

export class BTValidators {
  static json(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return { 'Invalid JSON': { value: value } };
      }
    }

    return null;
  }
}
