export class InitialPoseSummary {
  data_type = 'initial_pose_summary';
  date: number; //TODO change to Date Object?
  pose_x: number[];
  pose_y: number[];
  pose_qw: number[];
  pose_qz: number[];
  pose_count: number;

  org_id: string;
  org_name: string;
  store_id: string;
  store_name: string;
  robot_id: string;
  robot_name: string;
  start_date: number;
  end_date: number;

  constructor(report: any, orgId: string, orgName: string, storeId: string, storeName: string,
              robotId: string, robotName: string, startDate: number, endDate: number) {

    this.pose_x = report.pose_x;
    this.pose_y = report.pose_y;
    this.pose_qw = report.pose_qw;
    this.pose_qz = report.pose_qz;
    this.pose_count = report.pose_count;
    this.date = report.date * 1000.0;

    this.org_id = orgId;
    this.org_name = orgName;
    this.store_id = storeId;
    this.store_name = storeName;
    this.robot_id = robotId;
    this.robot_name = robotName;
    this.start_date = startDate * 1000.0;
    this.end_date = endDate * 1000.0;

  }
}
