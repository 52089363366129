import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ISelectOption } from '@ng-cloud/badger-core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MultiSelectPopupDialogComponent } from './multi-select-popup/multi-select-popup.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'bt-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
  }]
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @ViewChild('addBtn') addBtn: MatButton;

  @Output() search = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() nextPage = new EventEmitter<number>();

  @Input() multiple = false;
  @Input() valueKey = 'id';
  @Input() nameKey = 'name';
  @Input() tooltipKey;
  @Input() hideClearButton = false;
  @Input() searchText = 'Search...';
  @Input() searchAvailable = false;
  @Input() clearSelection: Observable<void>;
  @Input() savedSelection: Observable<ISelectOption[]>; // to restore saved selection


  @Input()
  get listItems(): ISelectOption[] {
    return this._listItems;
  }

  set listItems(value: ISelectOption[]) {
    this.listUpdated = true;

    if (this.selectValue.length && !this.listItemsCache.length && !this.selectedItemsCache.length && value.length) {
      this.selectedItemsCache = this.selectValue.map(i => value.find(item => item[this.valueKey] === i)).filter(v => !!v);
      this.selectedItems = [...this.selectedItemsCache];
      this.selectedViewData = this.selectedItemsCache.length ? this.selectedItemsCache : [ {[this.nameKey]:this.placeholder} ];
    }

    this.listItemsCache = value;
    this.selectValue = this.selectedItemsCache.map(i => i[this.valueKey]);

    if (this.multiple && this.selectedItems.length) {
      this._listItems = this.listItemsCache.filter(i => this.selectedItems.every(si => si[this.valueKey] !== i[this.valueKey]));
    }
    else {
      this._listItems = this.listItemsCache;
    }
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  @Input()
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  private onChange: (value: any[]) => void;
  private onTouched: () => void;

  private _listItems: ISelectOption[];
  private _placeholder: string;
  private _title: string;
  private searchFocused = false;
  private pageNum = 1;
  private listUpdated = false;

  private changeSubject = new BehaviorSubject<{ value: any[], shouldUpdate: boolean }>({ value: [], shouldUpdate: true });

  public selectedItemsCache: ISelectOption[] = [];
  public listItemsCache: ISelectOption[] = [];
  public selectedItems: ISelectOption[] = [];
  public selectValue: any[] = [];
  public selectedViewData: ISelectOption[];
  public searchValue = '';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.selectedViewData = [{[this.nameKey] : this.placeholder, [this.valueKey]: null }];

    this.initChangeListener();

    this.clearSelection.subscribe(() => {
      if (this.selectedItemsCache.length) {
        this.clear(true);
      }
    });

    this.savedSelection?.subscribe((items) => {
       this.selectedItemsCache = items;
       this.selectValue = this.selectedItemsCache.map(i => i[this.valueKey]);
       this.selectedItems = [...this.selectedItemsCache];
       this.selectedViewData = this.selectedItemsCache.length ? this.selectedItemsCache : [{ [this.nameKey]: this.placeholder}];
       this.changeSubject.next({ value: this.multiple ? this.selectValue : this.selectValue[0], shouldUpdate: true });
    });

  }

  private initChangeListener() {
    this.changeSubject.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev.value, curr.value)),
       skip(1))
      .subscribe(({ value, shouldUpdate }) => {
        if (shouldUpdate) {
          this.onChange(value);
          //this.selectionChange.emit(this.multiple ? value : value[0]);
          this.selectionChange.emit(this.multiple ? this.selectedItemsCache : this.selectedItemsCache[0]);
        }
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MultiSelectPopupDialogComponent, {
      data: {
        parent: this,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.menuClose();
    });

    // center popup dialog over the Add button
    const rect:DOMRect = this.addBtn._elementRef.nativeElement.getBoundingClientRect();
    const popupHeight = 275;

    // calc popup dialog position:
    let top = rect.bottom - popupHeight / 2;
    if ((top + popupHeight) > window.innerHeight) { // if beyond page area
      top = window.innerHeight - popupHeight - 10;
    }
    dialogRef.updatePosition({ top: `${top}px` /* '50px'*/, right: '50px' });
  }

  openEdit() {
    if (this.multiple) {
      this._listItems = this.listItemsCache.filter(i => this.selectedItems.every(si => si[this.valueKey] !== i[this.valueKey]));
    }

    this.openDialog();
  }

  clear(emitChange = true) {
    this.selectedItemsCache = [];
    this.selectedItems = [];
    this.selectValue = [];
    this.selectedViewData = [{ [this.nameKey]: this.placeholder}];
    if (emitChange) {
      this.searchValue = ''; // clear only if user clears
    }
    this.changeSubject.next({ value: this.multiple ? [] : null, shouldUpdate: emitChange });
    if (emitChange) {
      this.search.emit(this.searchValue);
    }
  }

  public menuClose() {
    this.selectValue = this.selectedItemsCache.map(i => i[this.valueKey]);
    this.selectedItems = [...this.selectedItemsCache];
    this.selectedViewData = this.selectedItemsCache.length ? this.selectedItemsCache : [{ [this.nameKey]: this.placeholder}];
    this.changeSubject.next({ value: this.multiple ? this.selectValue : this.selectValue[0], shouldUpdate: true });

    // clear search
    this.searchValue = '';
    this.search.emit(this.searchValue);

  }

  onSelectionChange(value, selected) {

    let item = this._listItems.find(i => i[this.valueKey] == value);
    if (!item && !selected) {
      item = this.selectedItems.find(i => i[this.valueKey] == value);
    }

    if (!item) {
      return;
    }

    if (this.multiple) {
      if (selected) {
        const exist = this.selectedItemsCache.some(i => item[this.valueKey] === i[this.valueKey]);
        this.selectedItemsCache = [...this.selectedItemsCache, ...(exist ? [] : [item])];
      }
      else {
        this.selectedItemsCache = this.selectedItemsCache.filter(i => i[this.valueKey] !== item[this.valueKey]);
      }
    }
    else {
      if (selected) {
        this.selectedItemsCache = [item];
      }
    }
  }

  writeValue(value: any): void {

    if (value) {
      this.selectValue = Array.isArray(value) ? [...value] : [value];
      if (!this.selectValue.length) {
        this.clear(false);
      }
      else if (this.listItemsCache.length) {
        this.selectedItemsCache = this.selectValue.map(i => this.listItemsCache.find(item => item[this.valueKey] === i)).filter(i => !!i);
        this.selectedItems = [...this.selectedItemsCache];
        this.selectedViewData = this.selectedItemsCache.length ? this.selectedItemsCache : [{ [this.nameKey]: this.placeholder}];
      }

      this.changeSubject.next({ value: value, shouldUpdate: false });
    } else {
      this.clear(false);
    }
  }

  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  adjustFormat(str: number | string): number | string {
    // make spaces meaningful, note &nbsp; doesn't work from .ts methods.
    if (typeof str == 'string') {
      return str.replace(/ /g, '\xA0');
    }

    return str;
  }

  ngOnDestroy(): void {
    this.changeSubject.complete();
  }

  searchEventValue(event: KeyboardEvent) {
    this.pageNum = 1;
    return (event.target as HTMLInputElement).value;
  }

  onListScroll(e, offsetHeight: number) {
    if ((e.target.scrollTop + offsetHeight + 5) >= e.target.scrollHeight) {

      if (this.listUpdated && this._listItems.length > 0) {
        this.pageNum += 1;
        this.listUpdated = false;
        this.nextPage.emit(this.pageNum);
      }
    }
  }

  getItemTooltip(key): string {
    let tooltip = '';
     const item = this.listItemsCache.find(i => i[this.valueKey] == key);
     if (item) {
       if (this.tooltipKey && item[this.tooltipKey]) {
        tooltip = item[this.tooltipKey].toString();
       } else {
        tooltip = item[this.nameKey].toString();
       }
     }

     return tooltip;
  }

}
