import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';

import { ViewportComponent } from '../viewport.component';
import { ViewportImagePosition } from '../../models/viewport-image';
import { Viewport } from '../../models/viewport';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'bt-viewport-nav-control',
  templateUrl: './viewport-nav-control.component.html',
  styleUrls: ['./viewport-nav-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewportNavControlComponent implements OnChanges {

  @ViewChild(MatButtonToggleGroup, { static: true }) navToggleGroup: MatButtonToggleGroup;
  @Input() viewportComponent: ViewportComponent;
  @Input() viewport: Viewport;

  viewportImages = ViewportImagePosition;

  constructor() {
  }

  ngOnChanges() {
    if (this.viewportComponent) {
      if (this.viewport) {
        this.navToggleGroup.value = 'center';

        this.viewportComponent.setViewport(this.viewport);
        this.viewportComponent.setImagePosition(this.viewportImages.center);
      }
      else {
        this.viewportComponent.setImageUrl(null);
      }
    }
  }

  setImage(imagePosition: string) {
    if (this.hasImage(imagePosition)) {
      this.navToggleGroup.value = imagePosition;
      this.viewportComponent.setImagePosition(imagePosition);
    }
  }

  getPosition(): string {
    return this.navToggleGroup.value;
  }

  hasImage(image: string): boolean {
    if (this.viewport) {
      return !!this.viewport.getImageUrl(image);
    }
    else {
      return false;
    }
  }
}
