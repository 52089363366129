import { Injectable } from '@angular/core';
import { ApiService, Task, TaskResolution } from '@ng-cloud/badger-core';
import { Observable, of as observableOf } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as _ from 'lodash';

export interface TaskListManager {
  getNextTask(currentTaskId: number): Observable<Task>;
  getTaskResolutions(): Observable<TaskResolution[]>;
}

@Injectable({
  providedIn: 'root'
})
export class TasksService extends ApiService {
  taskListManagers = new Map<number, TaskListManager>();
  apiName = 'insight';
  static cacheDuration = 600;

  // TODO: do we need params to just get active tasks?
  getTasks(storeId: number, params?: any): Observable<Task[]> {
    return this.list(Task, `stores/${storeId}/tasks`, params, TasksService.cacheDuration);
  }

  getTask(storeId: number, id: number): Observable<Task> {
    return this.get(Task, `stores/${storeId}/tasks/${id}`, 600);
  }

  tasks(storeId: number): Observable<any> {
    return this.cable().channel('StoreTaskListChannel', { store_id: storeId })
      .received().pipe(map(response => this.processTasksResults(JSON.parse(response))));
  }

  private processTasksResults(response: any) {
    const updatedTasks: Task[] = _.map(_.get(response, 'updated_tasks'), task => new Task().deserialize(task));
    const newTasks: Task[] = _.map(_.get(response, 'new_tasks'), task => new Task().deserialize(task));
    return { 'updated_tasks': updatedTasks, 'new_tasks': newTasks };
  }

  updateTask(task: Task): Observable<Task> {
    return this.update(task, `stores/${task.storeId}/tasks/${task.id}`);
  }

  getStats(storeId: number, params: any = {}): Observable<any> {
    return this.http.get(this.url('/stores/' + storeId + '/tasks/stats'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null)
    );
  }

  getTaskResolutions(orgId: number): Observable<TaskResolution[]> {
    return this.list(TaskResolution, `organizations/${orgId}/task_resolutions`, TasksService.cacheDuration);
  }

  getStoreTaskStats(storeId: number): Observable<any> {
    return this.cable().channel('StoreTaskStatsChannel', { 'store_id': storeId }).received().pipe(map(response => JSON.parse(response)));
  }

  getOrgTaskStats(orgGuid: string): Observable<any> {
    return this.cable().channel('OrgTaskStatsChannel', { 'org_guid': orgGuid }).received().pipe(map(response => JSON.parse(response)));
  }

  setTaskListManager(storeId: number, taskListManager: TaskListManager) {
    this.taskListManagers.set(storeId, taskListManager);
  }

  getTaskListManager(storeId: number): Observable<TaskListManager> {
    return observableOf(this.taskListManagers.get(storeId));
  }
}
