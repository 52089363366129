import { Directive, Inject, Input } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomDateFormat } from './custom-date-format';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[customDateFormat]',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useClass: CustomDateFormat
    }
  ]
})
export class CustomDateFormatDirective {
  private dateInputsMap: { days: string, weeks: string, months: string, years: string } = {
    days: 'MM-DD-YYYY',
    weeks: 'MM-DD-YYYY',
    months: 'MMM YYYY',
    years: 'YYYY'
  };

  @Input()
  set customDateFormat(format: string) {
    this.matDateFormat.updateDateFormat({ dateInput: this.dateInputsMap[format] });
  }

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: CustomDateFormat
  ) {
  }
}
