<div #container class="container" (scroll)="onListScroll($event)" (keydown)="onListKeyDown($event)">
  <mat-selection-list class="option-list" [multiple]="parent.multiple" [attr.data-qa]="'qa-multiselect-select-' + parent.title"
                      (selectionChange)="onSelectionChange($event)" color="primary">
    <container *ngIf="parent.multiple && parent.selectedItems.length">
      <span class="title selected">Selected</span>

      <mat-list-option togglePosition='before' [attr.data-qa]="'qa-selected-' + parent.title + '-' + item[parent.nameKey]"
                       *ngFor="let item of parent.selectedItems"
                       (onSelectionChange)="onSelectionChange($event, item)" [value]="item[parent.valueKey]"
                       title="{{parent.getItemTooltip(item[parent.valueKey])}}" [selected]="true" (keydown)="onListOptionKeyDown($event)">
        {{ adjustFormat(item[parent.nameKey].toString()) }}
      </mat-list-option>
    </container>

    <mat-divider *ngIf="parent.multiple"></mat-divider>

    <div *ngIf="parent.searchAvailable" class="searchBar">
      <input #searchInput [attr.data-qa]="'qa-multiselect-search-input-' + parent.title" matInput type="text"
             autocomplete="off" [(ngModel)]="parent.searchValue" (keyup)="onSearchKeyUp($event)"
             (keydown)="onSearchKeyDown($event)" (focus)="onSearchFocus()" (blur)="onSearchBlur()"
             placeholder="{{parent.searchText}}">
      <mat-divider></mat-divider>
    </div>

    <mat-divider *ngIf="parent.searchAvailable"></mat-divider>

    <mat-list-option togglePosition='before'
                     [attr.data-qa]="(parent.selectedItemsCache[0] && item[parent.nameKey]==parent.selectedItemsCache[0][parent.nameKey] ? 'qa-selected-' : 'qa-not-selected-') + parent.title + '-' + item[parent.nameKey]"
                     *ngFor="let item of parent.listItems" (selectionChange)="parent.onSelectionChange($event, item)"
                     [value]="item[parent.valueKey]" title="{{parent.getItemTooltip(item[parent.valueKey])}}"
                     (keydown)="onListOptionKeyDown($event)"
                     [selected]="!parent.multiple && parent.selectedItemsCache[0] && item[parent.valueKey]==parent.selectedItemsCache[0][parent.valueKey]">
      {{ adjustFormat(item[parent.nameKey].toString()) }}
    </mat-list-option>
  </mat-selection-list>

  <p data-qa="qa-ml-search-result" class="no-result" *ngIf="!parent.listItems.length && parent.searchValue">No Search Result</p>
</div>
