import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { CaptureEvent } from '../../models/capture-event';
import { CaptureEventAssessmentReview } from '../../models/capture-event-assessment-review';
import { InspectAssessment } from '../../models/inspect-assessment';
import * as _ from 'lodash';
import { RobotService } from '../../services/robot.service';
import { InspectAssessmentService } from '@ng-cloud/badger-core';

@Component({
    selector: 'bt-capture-event-assessment-detail',
    templateUrl: './capture-assessment-review-detail.component.html',
    styleUrls: ['./capture-assessment-review-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class CaptureAssessmentReviewDetailComponent implements OnChanges {
  @ContentChild(TemplateRef) assessmentReviewDetailTemplate;
  @Input() captureEvent: CaptureEvent;
  assessmentReview: CaptureEventAssessmentReview;
  inspectAssessment: InspectAssessment;
  activeTab: number;
  // @Output() closeEmitter: EventEmitter<any> = new EventEmitter();

  @Output() close = new EventEmitter();

  constructor(
    private robotService: RobotService,
    private reviewService: InspectAssessmentService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const captureEventChange = changes['captureEvent'];
    if (_.get(captureEventChange, 'currentValue.id') !== _.get(captureEventChange, 'previousValue.id')) {
      this.captureEvent.views.every(view => {
        if (view.assessmentReviews.length > 0) {
          //Select first assessment review & break
          this.selectReview(view.assessmentReviews[0].id);
          return false;
        }
        return true;
      });
      this.activeTab = 0; // Reset to the first review tab
    }
  }

  onClose() {
    this.close.emit(null);
  }

  selectReview(id: number) {
    if (id) {
      this.robotService.getCaptureEventAssessmentReview(id).subscribe(review => {
        this.assessmentReview = review;
        this.reviewService.getInspectAssessment(this.assessmentReview.assessmentId).subscribe(assessment => this.inspectAssessment = assessment);
      });
    }
    else {
      this.assessmentReview = null;
      this.inspectAssessment = null;
    }
  }
}
