import { Pose } from '@ng-cloud/badger-core';
import { fabric } from 'fabric';
import { MapObject } from '@ng-cloud/badger-core/map/objects/map-object';

export class InitSummaryObject extends MapObject {
  myPose: Pose;

  constructor(pose: Pose, options?: any) {
    super(options);
    this.myPose = pose;
  }

  protected build(): fabric.Object {
    return new fabric.Triangle({
      width: 16, height: 16, fill: '#ff6633', opacity: 0.7,
      top: this.myPose.y, left: this.myPose.x, stroke: '#e63900',
      selectable: false, hoverCursor: 'pointer'
    });
  }
}
