<bt-navbar #navbar [title]="title">
  <span signedInLeftAlign>
    <div fxLayoutAlign="center center">
      <bt-age-queue-chips [filter]="'insight'"></bt-age-queue-chips>
      <bt-insight-queue-chips [componentBreakpoint]="'gt-sm'" [showOrgSwitcher]="true"
                              *hasAccess="['insight_moderator', 'admin']"
                              (chipSelect)="chipClicked($event)"></bt-insight-queue-chips>
    </div>
  </span>

  <ng-container signedInRightAlign>
    <a *hasAccess="['insight_moderator']" mat-button routerLink="reviews">Review</a>
  </ng-container>

  <ng-container smallScreenItems>
    <button #navMenuButton *ngIf="authService.hasCurrentUser()" mat-icon-button #navMenuTrigger="matMenuTrigger"
            [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-md>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu xPosition="before">
      <a mat-menu-item *hasAccess="['insight_moderator']" routerLink="/">Review</a>
      <a mat-menu-item routerLink="users/profile">Profile</a>
      <button mat-menu-item (click)="navbar.signOut()">Sign Out</button>
      <mat-divider class="profile-menu-divider"></mat-divider>
      <mat-button-toggle-group name="switchTheme" [value]="navbar.currentTheme" class="theme-toggle-button-group"
                               [hideSingleSelectionIndicator]="true">
        <mat-button-toggle value="badger-light" (change)="navbar.toggleTheme($event.value)" class="theme-toggle-button"
                           matTooltip="Light Theme" [matTooltipShowDelay]="600">
          <mat-icon class="theme-toggle-icon">light_mode</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="badger-dark" (change)="navbar.toggleTheme($event.value)" class="theme-toggle-button"
                           matTooltip="Dark Theme" [matTooltipShowDelay]="600">
          <mat-icon class="theme-toggle-icon">dark_mode</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-menu>
  </ng-container>
</bt-navbar>

<div class="content-container">
  <router-outlet></router-outlet>
</div>
