import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Review } from './review';


export class InspectAuditEntry extends AbstractApiModel<InspectAuditEntry> {
  isUrgent: boolean;
  responseMatches: boolean;
  auditSequence: number;

  reviewId: number;
  moderatorId: number;
  inspectAuditId: number;

  review: Review;

  deserialize(json: any): this {
    this.isUrgent = json.is_urgent;
    this.responseMatches = json.response_matches;
    this.auditSequence = json.audit_sequence;
    this.reviewId = json.review_id;
    this.moderatorId = json.moderator_id;
    this.inspectAuditId = json.inspect_audit_id;

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      is_urgent: this.isUrgent,
      response_matches: this.responseMatches,
      audit_sequence: this.auditSequence,
      review_id: this.reviewId,
      moderator_id: this.moderatorId,
      inspect_audit_id: this.inspectAuditId
    });
  }
}
