<div mat-dialog-content #commentList class="comment-list" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="comment-header">
    <span>Comments</span>
    <button id="addCommentButton" mat-icon-button (click)="openCreate()">
      <mat-icon>add_comment</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <mat-table #table class="comment-table" [dataSource]="dataTable" >
    <ng-container matColumnDef="content">
      <mat-cell fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start" *matCellDef="let comment" style="padding: 10px">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between">
          <span class="comment-user">{{comment.user?.firstName + " " +comment.user?.lastName }}</span>
          <span class="comment-date">{{comment.updatedAt | date:'short'}}</span>
        </div>
        <div class="comment-body" fxLayout="row">
          <span>{{comment.body}}</span>
        </div>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: dataTable?.displayedColumns;"></mat-row>
  </mat-table>
</div>
