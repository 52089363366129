import { Injectable } from '@angular/core';
import { TaskType } from '../models/task';
import { Path } from '../models/path';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import * as _ from 'lodash';

export interface TaskTypeFilter {
  type: TaskType;
  name: string;
}

const taskTypeFilters: TaskTypeFilter[] = [
  { type: TaskType.PRICE, name: 'Price' },
  { type: TaskType.SALE_PRICE, name: 'Sale Price' },
  { type: TaskType.HOLE, name: 'Out-of-Stock' },
  { type: TaskType.WRONG, name: 'Wrong' }];

@Injectable({
  providedIn: 'root'
})
export class TaskFilterService {
  private selectedTaskTypeFilters = new BehaviorSubject<TaskTypeFilter[]>([]);
  private taskTypeFilterSelections = new SelectionModel<TaskType>(true, []);

  private selectedPaths = new BehaviorSubject<Path[]>([]);
  private pathSelections = new SelectionModel<Path>(false, []);

  constructor() {
    this.taskTypeFilterSelections.changed.subscribe(() => {
        this.notifyTaskFilterSelectionChange();
      }
    );

    this.pathSelections.changed.subscribe(() => {
        this.notifyPathSelectionChange();
      }
    );
  }

  setSelectedTaskTypes(taskTypes: TaskType[]) {
    this.taskTypeFilterSelections.setSelection(...taskTypes);
  }

  addSelectedTaskType(taskType: TaskType) {
    this.taskTypeFilterSelections.select(taskType);
  }

  removeSelectedTaskType(taskType: TaskType) {
    this.taskTypeFilterSelections.deselect(taskType);
  }

  public getTaskTypeFilters(): TaskTypeFilter[] {
    return taskTypeFilters;
  }

  private notifyTaskFilterSelectionChange() {
    const selected: TaskTypeFilter[] = [];

    _.forEach(this.taskTypeFilterSelections.selected, selection => {
      selected.push(_.find(taskTypeFilters, filter => filter.type === selection));
    });

    this.selectedTaskTypeFilters.next(selected);
  }

  public getSelectedTaskTypeFilters(): Observable<TaskTypeFilter[]> {
    return this.selectedTaskTypeFilters.asObservable();
  }

  private notifyPathSelectionChange() {
    this.selectedPaths.next(this.pathSelections.selected);
  }

  setSelectedPaths(paths: Path[]) {
    this.pathSelections.setSelection(...paths);
  }

  public getSelectedPaths(): Observable<Path[]> {
    return this.selectedPaths.asObservable();
  }
}
