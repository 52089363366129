import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  ClientSideDataTable,
  Column,
  Comment,
  Commentable,
  CommentFormComponent,
  CommentService,
  DialogService,
  UserService
} from '@ng-cloud/badger-core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';

@Component({
  selector: 'bt-compact-comments',
  standalone: false,
  templateUrl: './compact-comments.component.html',
  styleUrl: './compact-comments.component.css'
})
export class CompactCommentsComponent implements OnInit {
  dataTable: ClientSideDataTable<Comment>;

  _commentable: Commentable;
  get commentable(): Commentable {
    return this._commentable;
  }

  @Input() set commentable(commentable: Commentable) {
    this._commentable = commentable;
    setTimeout(() => this.fetchComments(), 50);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompactCommentsComponent>,
    public dialog: MatDialog,
    protected commentService: CommentService,
    protected dialogService: DialogService,
    protected userService: UserService) {

    this.commentable = data.commentable;
  }

  ngOnInit() {
    const columns = [new Column('content')];
    this.dataTable = new ClientSideDataTable<Comment>(columns);
  }

  fetchComments() {
    this.commentService.getComments(this.commentable).subscribe(comments => {
      this.dataTable.data = _.orderBy(comments, 'createdAt', 'desc');
      comments.forEach(comment => {
        this.userService.getUser(comment.userId).subscribe(user => comment.user = user);
      });
    });
  }

  openCreate(): void {
    const dialogRef = this.dialog.open(CommentFormComponent, {
      data: {
        comment: new Comment(),
        commentable: this.commentable
      }
    });
    dialogRef.afterClosed().subscribe(comment => {
      if (comment) {
        this.commentable.comments.push(comment);
        this.userService.getUser(comment.userId).subscribe(user => comment.user = user);
        this.dataTable.data = _.orderBy(this.dataTable.data.concat([comment]), 'createdAt', 'desc');
      }
    });
  }

  closeDialog(data: any): void {
    this.dialogRef.close(data.data);
  }
}
