import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AuthorizationService, CustomIconService, ErrorService, MessageService, TitleService } from '@ng-cloud/badger-core';
import { environment } from '../environments/environment';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'insight-review-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private resizeObservable: ResizeObserver;
  private _navMenuButton: MatButton;
  @ViewChild('navMenuTrigger') navMenuTrigger: MatMenuTrigger;

  @ViewChild('navMenuButton') set navMenuButton(navMenuButton: MatButton) {
    this._navMenuButton = navMenuButton;
    if (this._navMenuButton) {
      this.resizeObservable.observe(this._navMenuButton._elementRef.nativeElement);
    }
  }

  title = 'InSight Moderation';

  constructor(
    public authService: AuthorizationService,
    private messageService: MessageService,
    private errorService: ErrorService,
    private customIconService: CustomIconService,
    private router: Router
  ) {
    TitleService.appTitle = this.title;
    ApiService.apis = environment.apis;

    this.customIconService.addCustomIcon('badger-light', '../assets/badger_dark.svg');
    this.customIconService.addCustomIcon('badger-dark', '../assets/badger_dark.svg');

    this.customIconService.addCustomIcon('insight', '../assets/insight.svg');
    this.customIconService.addCustomIcon('camera', '../assets/camera.svg');
    this.customIconService.addCustomIcon('arrow', '../assets/arrow.svg');
    this.customIconService.addCustomIcon('top_stock', '../assets/barcode.svg');

    this.customIconService.addCustomIcon('price_mismatch_alt', '../assets/price_alt.svg');
    this.customIconService.addCustomIcon('sale_price_mismatch_alt', '../assets/sale_price_alt.svg');
    this.customIconService.addCustomIcon('hole_oos_alt', '../assets/out_alt.svg');
    this.customIconService.addCustomIcon('out_of_stock_alt', '../assets/out_alt.svg');
    this.customIconService.addCustomIcon('eOOS_alt', '../assets/wrong_alt.svg');
    this.customIconService.addCustomIcon('viewport_alt', '../assets/viewport_alt.svg');
    this.customIconService.addCustomIcon('viewport_issue_alt', '../assets/viewport_alt.svg');
    this.customIconService.addCustomIcon('unconfirmed_product_alt', '../assets/unconfirmed_product_alt.svg');

    this.customIconService.addCustomIcon('price_mismatch', '../assets/price.svg');
    this.customIconService.addCustomIcon('sale_price_mismatch', '../assets/sale_price.svg');
    this.customIconService.addCustomIcon('hole_oos', '../assets/out.svg');
    this.customIconService.addCustomIcon('out_of_stock', '../assets/out.svg');
    this.customIconService.addCustomIcon('eOOS', '../assets/wrong.svg');
    this.customIconService.addCustomIcon('viewport', '../assets/viewport.svg');
    this.customIconService.addCustomIcon('viewport_issue', '../assets/viewport.svg');
    this.customIconService.addCustomIcon('unconfirmed_product', '../assets/unconfirmed_product.svg');

    this.customIconService.loadCustomIcons();
  }

  ngOnInit() {
    this.errorService.exceptions().subscribe(exception => {
      this.messageService.alert(exception.error, exception);
    });
    this.errorService.alerts().subscribe(alert => {
      this.messageService.alert(alert);
    });
  }

  chipClicked(e) {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigateByUrl('/reviews/review/' + e);
  }

  ngOnDestroy(): void {
    this.resizeObservable && this.resizeObservable.disconnect();
  }

  ngAfterViewInit(): void {
    this.createResizeObservable();
  }

  createResizeObservable() {
    this.resizeObservable = new ResizeObserver(entries => {
      if (entries[0].contentRect.width === 0) {
        this.navMenuTrigger.closeMenu();
      }
    });
  }
}
