import { Store } from '../store';

export interface HasStore {
  storeId: number;
  store: Store;
}

export interface HasMultiStore {
  storeIds: number[];
  stores: Store[];
}
