<div fxLayout="row" fxFlex="100%">

  <div fxFlex="35%" class="table-div">

    <mat-table #queueTable [dataSource]="dataTable">
      <ng-container matColumnDef="cam">
        <mat-header-cell *matHeaderCellDef fxFlex="50%"> Camera</mat-header-cell>
        <mat-cell *matCellDef="let focusResult" fxFlex="50%"> {{ focusResult.cam | startCase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxFlex="50%"> Status</mat-header-cell>
        <mat-cell *matCellDef="let focusResult" fxFlex="50%">
          <bt-boolean [value]="focusResult.succeeded" trueColor=""></bt-boolean>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef fxFlex="70px"></mat-header-cell>
        <mat-cell *matCellDef="let focusResult" fxFlex="70px">
          <span>
            <button mat-icon-button matTooltip="Display Image" (click)="displayImage(focusResult)">
              <mat-icon>image</mat-icon>
            </button>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="log">
        <mat-header-cell *matHeaderCellDef fxFlex="70px"></mat-header-cell>
        <mat-cell *matCellDef="let focusResult" fxFlex="70px">
          <span>
            <button mat-icon-button matTooltip="Display Log File" (click)="displayLogFile(focusResult)">
              <mat-icon>article</mat-icon>
            </button>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="download">
        <mat-header-cell *matHeaderCellDef fxFlex="70px"></mat-header-cell>
        <mat-cell *matCellDef="let focusResult" fxFlex="70px">
          <span>
            <button mat-icon-button matTooltip="Download Log File" (click)="download(focusResult)">
              <mat-icon>download</mat-icon>
            </button>
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataTable.displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataTable.displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <div fxFlex="65%" class="viewer-div" fxLayout="column">
    <div *ngIf="displayMode != CLEAR_MODE" fxFlex="60px" class="camera-text">{{focusResult?.cam}}</div>
    <img [ngClass]="displayMode == IMAGE_MODE ? '' : 'hidden'" fxFlex="100%" class="shelf-image"
         src="{{imageUrl}}" alt="shelf image"/>
    <iframe [ngClass]="displayMode == LOG_FILE_MODE ? '' : 'hidden'" fxFlex="100%" class="text-frame" #logFileFrame
            ></iframe>
  </div>
</div>




