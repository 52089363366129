
export interface IPagination {
  per: number,
  page: number
}

export interface ISelectOption {
  [key: string]: number | string;
}

export type AnalyticsType = 'pm' | 'osa' | 'oos' | 'pos';
export type AnalyticGroup = 'products' | 'categories' | 'stores' | 'departments' | 'organizations' | 'units_sold' | 'transactions' | 'revenue';
