import { Component, Input, OnChanges } from '@angular/core';
import { Heartbeat } from '@ng-cloud/badger-core/models/heartbeat';

@Component({
    selector: 'bt-battery-status',
    templateUrl: './battery-status.component.html',
    styleUrls: ['./battery-status.component.scss'],
    standalone: false
})
export class BatteryStatusComponent implements OnChanges {
  @Input() heartbeat: Heartbeat;
  batteryIcon: string;
  batteryTooltip: string;
  batteryWarning: boolean;

  getBatteryIcon(percentage: number, charging: boolean): string {
    let iconName = 'battery';

    iconName = iconName.concat(charging ? '_charging' : '');

    if (percentage < 10) {
      iconName = iconName.concat('_low');
    }
    else if (percentage < 25) {
      iconName = iconName.concat('_20');
    }
    else if (percentage < 40) {
      iconName = iconName.concat('_30');
    }
    else if (percentage < 55) {
      iconName = iconName.concat('_50');
    }
    else if (percentage < 70) {
      iconName = iconName.concat('_60');
    }
    else if (percentage < 85) {
      iconName = iconName.concat('_80');
    }
    else if (percentage < 95) {
      iconName = iconName.concat('_90');
    }
    else {
      iconName = iconName.concat('_full');
    }

    return iconName;
  }

  ngOnChanges() {
    this.batteryWarning = false;

    switch (this.heartbeat && this.heartbeat.batteryStatus) {
      case 'charging':
        this.batteryIcon = this.getBatteryIcon(this.heartbeat.batteryPercentage, true);
        this.batteryTooltip = 'Charging';
        break;
      case 'discharging':
        this.batteryIcon = this.getBatteryIcon(this.heartbeat.batteryPercentage, false);
        this.batteryTooltip = 'Discharging';
        if (this.heartbeat.batteryPercentage < 10) {
          this.batteryWarning = true;
        }
        break;
      case 'off':
        if (this.heartbeat.batteryPercentage < 80) {
          this.batteryIcon = 'battery_alert';
          this.batteryTooltip = 'On Charger (below threshold)';
          this.batteryWarning = true;
        }
        else {
          this.batteryIcon = this.getBatteryIcon(this.heartbeat.batteryPercentage, true);
          this.batteryTooltip = 'On Charger (not charging)';
        }
        break;
      default:
        this.batteryIcon = 'battery_unknown';
        this.batteryTooltip = 'Status Unknown';
    }
  }
}
