import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientSideDataTable, Column } from '@ng-cloud/badger-core/common/data-table';
import { RobotService } from '@ng-cloud/badger-core/services/robot.service';
import { FocusResult } from '@ng-cloud/badger-core/models/focus-result';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

@Component({
    selector: 'bt-robot-focus-play-details',
    templateUrl: './robot-focus-play-details.component.html',
    styleUrls: ['./robot-focus-play-details.component.scss'],
    standalone: false
})
export class RobotFocusPlayDetailsComponent implements OnInit {
  readonly CLEAR_MODE = 'clear';
  readonly IMAGE_MODE = 'image';
  readonly LOG_FILE_MODE = 'logFile';

  @ViewChild('logFileFrame', { static: false }) logFileFrame;

  playExecutionId: number;
  focusResult: FocusResult;
  imageUrl: string;

  displayMode = this.CLEAR_MODE;

  dataTable: ClientSideDataTable<FocusResult> = new ClientSideDataTable<FocusResult>([
    new Column('cam'),
    new Column('status'),
    new Column('image'),
    new Column('log'),
    new Column('download')
  ]);

  constructor(
    private route: ActivatedRoute,
    protected robotService: RobotService,
    protected http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.playExecutionId = +params.get('playExecutionId');
      this.robotService.getFocusResults(this.playExecutionId).subscribe((focusResults) => {
        this.dataTable.data = _.sortBy(focusResults, focusResult => focusResult.cam).reverse();
      });
    });
  }

  setFocusResult(focusResult: FocusResult) {
    this.focusResult = focusResult;
  }

  clearDisplay() {
    this.displayMode = this.CLEAR_MODE;
  }

  setDisplayMode(displayMode) {
    this.displayMode = displayMode;
  }

  displayImage(focusResult: FocusResult) {
    this.setDisplayMode(this.IMAGE_MODE);
    this.setFocusResult(focusResult);
    this.imageUrl = focusResult.focusImageUrl;
  }

  displayLogFile(focusResult: FocusResult) {
    this.setDisplayMode(this.LOG_FILE_MODE);
    this.setFocusResult(focusResult);
    this.logFileFrame.nativeElement.src = focusResult.focusLogUrl;
  }

  download(focusResult: FocusResult) {
    fetch(focusResult.focusLogUrl)
      .then(r => r.text())
      .then(t => saveAs(new Blob([t], { type: 'text/plain;charset=utf-8' }), 'band_' + focusResult.focusBand + '_' + focusResult.cam + '_focus_log.txt'));
  }
}
