<div class="multi-select">
  <div class="title-bar">
    <span title="" class="title">{{title}}</span>

    <button [attr.data-qa]="'qa-multiselect-clear-' + title" *ngIf="!hideClearButton" type="button"
      [disabled]="!selectedItemsCache.length" mat-icon-button class="icon-button-small" (click)="clear()" title="Reset">
      <mat-icon class="multi-select-button">clear</mat-icon>
    </button>

    <button #addBtn [attr.data-qa]="'qa-multiselect-add-' + title" mat-icon-button class="icon-button-small" type="button"
      (click)="openEdit()" title="Open">
      <mat-icon class="multi-select-button">add</mat-icon>
    </button>
  </div>

  <mat-list>
    <mat-list-item class="selected-list" *ngFor="let selection of this.selectedViewData" [title]="getItemTooltip(selection[valueKey])">
      <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" matListItemTitle
        [attr.data-qa]="'qa-selected-list-' + title + '-' + selection[nameKey].toString()">
        <div fxFlex="6px" class="dot"></div>
        <span class="selected-list__item" >{{adjustFormat(selection[nameKey].toString())}}</span>
      </span>
    </mat-list-item>
  </mat-list>
</div>
