import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { PlayExecution } from '@ng-cloud/badger-core/models/play-execution';
import { RobotService } from '@ng-cloud/badger-core/services/robot.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabNav } from '@angular/material/tabs';

@Component({
  selector: 'bt-play-execution-nav',
  templateUrl: './play-execution-nav.component.html',
  styleUrls: ['./play-execution-nav.component.scss']
})
export class PlayExecutionNavComponent implements OnChanges {
  @ViewChild('playExecutionTabNav') playExecutionTabNav: MatTabNav;
  @Input() robotId: number;
  @Input() currentPlayExecution: PlayExecution;
  @Input() jobType: string;
  playExecutions: PlayExecution[] = [];

  constructor(
    protected robotService: RobotService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnChanges(): void {
    let params = {};
    if (this.jobType) {
      params = { 'job_type': this.jobType };
    }
    this.robotService.getPlayExecutionsForNav(this.currentPlayExecution.id, params).subscribe((playExecutions => {
      this.playExecutions = _.sortBy(playExecutions, 'startTime');

      setTimeout(() =>
        this.playExecutionTabNav.selectedIndex = _.findIndex(this.playExecutions, playExecution => playExecution.id === this.currentPlayExecution.id), 50);
    }));
  }

  tabSelected(playExecution: PlayExecution) {
    if (playExecution && playExecution.id !== this.currentPlayExecution.id) {
      this.currentPlayExecution = playExecution;
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigate(['..', playExecution.id], { relativeTo: this.route });
    }
  }
}
