<mat-button-toggle-group #navToggleGroup value="center" fxLayout="column" fxFlex="124px">
  <div fxLayout="row">
    <mat-button-toggle [disabled]="!hasImage(viewportImages.upLeft)" value="{{viewportImages.upLeft}}" class="viewport-nav"
                       (change)="setImage(viewportImages.upLeft)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="up-left"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.upCenter)" value="{{viewportImages.upCenter}}" class="viewport-nav"
                       (change)="setImage(viewportImages.upCenter)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="up"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.upRight)" value="{{viewportImages.upRight}}" class="viewport-nav"
                       (change)="setImage(viewportImages.upRight)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="up-right"></mat-icon>
    </mat-button-toggle>
  </div>

  <div fxLayout="row">
    <mat-button-toggle [disabled]="!hasImage(viewportImages.left)" value="{{viewportImages.left}}" class="viewport-nav"
                       (change)="setImage(viewportImages.left)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="left"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.center)" value="{{viewportImages.center}}" class="viewport-nav"
                       (change)="setImage(viewportImages.center)" fxFlex="33%">
      <mat-icon svgIcon="camera"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.right)" value="{{viewportImages.right}}" class="viewport-nav"
                       (change)="setImage(viewportImages.right)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="right"></mat-icon>
    </mat-button-toggle>
  </div>

  <div fxLayout="row">
    <mat-button-toggle [disabled]="!hasImage(viewportImages.downLeft)" value="{{viewportImages.downLeft}}" class="viewport-nav"
                       (change)="setImage(viewportImages.downLeft)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="down-left"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.downCenter)" value="{{viewportImages.downCenter}}" class="viewport-nav"
                       (change)="setImage(viewportImages.downCenter)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="down"></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [disabled]="!hasImage(viewportImages.downRight)" value="{{viewportImages.downRight}}" class="viewport-nav"
                       (change)="setImage(viewportImages.downRight)" fxFlex="33%">
      <mat-icon svgIcon="arrow" class="down-right"></mat-icon>
    </mat-button-toggle>
  </div>
</mat-button-toggle-group>

