export interface ApiModel {
  id: number;
  revision: number;
  createdAt: Date;
  updatedAt: Date;

  serialize(): any;

  deserialize(json: any): this;

  assign(params: any): this;

  sortValue(): number;
}
