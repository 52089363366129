import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InspectAudit } from '../models/inspect-audit';
import { ApiService } from '../services/api.service';
import { InspectAuditEntry } from '@ng-cloud/badger-core/models/inspect-audit-entry';

@Injectable()
export class InspectAuditService extends ApiService {

  apiName = 'inspect';

  /**
   * Fetch InspectAudits
   */
  getInspectAudits(params?: any): Observable<InspectAudit[]> {
    return this.list(InspectAudit, 'inspect_audits', params);
  }

  /**
   * Fetch an InspectAudit for id
   */
  getInspectAudit(id: number): Observable<InspectAudit> {
    return this.get(InspectAudit, `inspect_audits/${id}`);
  }

  updateInspectAudit(inspectAudit: InspectAudit): Observable<InspectAudit> {
    return this.update(inspectAudit, `inspect_audits/${inspectAudit.id}`);
  }

  completeInspectAudit(inspectAudit: InspectAudit): Observable<InspectAudit> {
    return this.update(inspectAudit, `inspect_audits/${inspectAudit.id}/complete`);
  }

  /**
   * Create Inspect Audit
   * @param {InspectAudit} audit
   * @returns {Observable<InspectAudit>}
   */
  createInspectAudit(audit: InspectAudit): Observable<InspectAudit> {
    return this.create(audit, 'inspect_audits');
  }

  getInspectAuditEntries(params?: any): Observable<InspectAuditEntry[]> {
    return this.list(InspectAuditEntry, 'inspect_audit_entries', params);
  }

  getModeratorStats(params?: any): Observable<any> {
    return this.http.get(this.url('inspect_audit_entries/moderator_stats'), { params: ApiService.params(params) });
  }

  getNextEntry(auditId: number, currentEntryId: number = null): Observable<InspectAuditEntry> {
    const params = currentEntryId ? { inspect_audit_entry_id: currentEntryId.toString() } : {};

    return this.http.patch(this.url(`inspect_audits/${auditId}/next`), { params: params }).pipe(
      filter(response => response != null),
      map(response => new InspectAuditEntry().deserialize(response)));
  }

  getPreviousEntry(auditId: number, currentEntryId: number): Observable<InspectAuditEntry> {
    const params = { inspect_audit_entry_id: currentEntryId.toString() };

    return this.http.get(this.url(`inspect_audits/${auditId}/previous`), { params: params }).pipe(
      filter(response => response != null),
      map(response => new InspectAuditEntry().deserialize(response)));
  }

  updateAuditEntry(entry: InspectAuditEntry): Observable<InspectAuditEntry> {
    return this.update(entry, `inspect_audits/${entry.inspectAuditId}/inspect_audit_entries/${entry.id}`);
  }
}
